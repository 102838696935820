<template>
    <section id="customer-reviews-block">
        <div class="content">
            <h6>What our <br> clients say</h6>
            <div class="separation-line"/>
            <reviews-slider class="slider"/>
            <div class="separation-line"/>
            <!--            <button>Try it for Free</button>-->
        </div>
    </section>
</template>

<script>
    import ReviewsSlider from "@/components/HomePageComponents/ReviewsSlider/index.vue";

    export default {
        components: {
            "reviews-slider": ReviewsSlider
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
