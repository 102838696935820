<template>
    <div class="stone-shape configurator-options">
        <h4>Stone Shape</h4>
        <v-list class="configurator-options-list pa-0">
            <v-list-item-group
                v-model="stoneShape"
                mandatory
                class="d-flex">
                <template v-for="(shape, idx) in stoneShapes[builder]">
                    <v-list-item
                        :key="`item-${idx}`"
                        :value="shape.value"
                        :disabled="isDisabled(shape)"
                        :ripple="false"
                        class="configurator-option pa-0"
                        dark>
                        <template v-slot:default>
                            <v-list-item-content class="pa-0">
                                <div class="configurator-option__img-wrap">
                                    <img :src="shape.image" :alt="shape.label">
                                </div>
                                <p class="mb-0">{{ shape.label }}</p>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    export default {
        name: "stone-shape",
        props: {
            value: {
                type: String,
                default: "RD"
            },
            builder: {
                type: String,
                default: "veraWang" // veraWang, taylorYou, silentOpus
            },
            config: {
                type: Object,
                default: null
            },
            unAvailableCombinations: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                // stoneShape: this.$props.value,
                stoneShapes: {
                    veraWang: [
                        {
                            value: "RD",
                            label: "Round",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/diamond-shapes-round.png"
                        },
                        {
                            value: "CU",
                            label: "Cushion",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/diamond-shapes-cushion.png"
                        },
                        {
                            value: "PE",
                            label: "Pear",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/diamond-shapes-pear.png"
                        },
                        {
                            value: "OV",
                            label: "Oval",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/diamond-shapes-oval.png"
                        },
                        {
                            value: "EM",
                            label: "Emerald",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/diamond-shapes-emerald.png"
                        }
                    ],
                    silentOpus: [
                        {
                            value: "RD",
                            label: "Round",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/diamond-shapes-round.png"
                        },
                        {
                            value: "PC",
                            label: "Princess",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/diamond-shapes-square.png"
                        }
                    ]
                }
            }
        },
        computed: {
            stoneShape: {
                get() {
                    return this.$props.value;
                },
                set(nv) {
                    this.$emit("onChange", nv);
                }
            }
        },
        methods: {
            isDisabled(shape) {
                if (this.builder === "veraWang") {
                    const combinations = this.unAvailableCombinations.filter(comb => comb.stoneShapes.includes(shape.value));
                    return combinations.some(comb => {
                        return (comb.centerSettings.length ? comb.centerSettings.includes(this.config.centerSetting) : true) &&
                            (comb.mountingStyles.length ? comb.mountingStyles.includes(this.config.mountingStyle) : true) &&
                            (comb.gemstones.length ? comb.gemstones.includes(this.config.gemstone) : true)
                    });
                }

                if (this.builder === "silentOpus") {
                    return this.unAvailableCombinations.includes(shape.value);
                }

                return false;
            }
        }
    }
</script>
