<template>
    <section id="beyond-photography-block">
        <div class="info-block">
            <h6>Go beyond Photography</h6>
            <div class="separation-line"/>
            <p>
                3D rendering allows for the ultimate immersive product
                experience. Choose your ideal angle, lighting, and setting.
            </p>
            <!--            <button v-if="deviceWidth()>767">-->
            <!--                Try our Demo-->
            <!--            </button>-->
        </div>
        <div class="items-preview">
            <div class="line-offset">
                <div class="order-1 order-md-0">
                    <div class="item item__1">
                        <!--                        <button>-->
                        <!--                            <img-->
                        <!--                                src="@/assets/HomePageImages/download-btn.svg">-->
                        <!--                        </button>-->
                        <img :src="require(`@/assets/HomePageImages/photography-block/1.png`)">
                    </div>
                    <div class="item item__2">
                        <!--                        <button>-->
                        <!--                            <img-->
                        <!--                                src="@/assets/HomePageImages/download-btn.svg">-->
                        <!--                        </button>-->
                        <img :src="require(`@/assets/HomePageImages/photography-block/2.png`)">
                    </div>
                </div>
                <div class="order-5 order-md-0 item item__3">
                    <!--                    <button>-->
                    <!--                        <img src="@/assets/HomePageImages/download-btn.svg">-->
                    <!--                    </button>-->
                    <img :src="require(`@/assets/HomePageImages/photography-block/3_1.png`)">
                </div>
                <div class="order-3 order-md-0">
                    <div class="item item__4">
                        <!--                        <button>-->
                        <!--                            <img-->
                        <!--                                src="@/assets/HomePageImages/download-btn.svg">-->
                        <!--                        </button>-->
                        <img :src="require(`@/assets/HomePageImages/photography-block/4.png`)">
                    </div>
                    <div class="item item__5">
                        <!--                        <button>-->
                        <!--                            <img-->
                        <!--                                src="@/assets/HomePageImages/download-btn.svg">-->
                        <!--                        </button>-->
                        <img :src="require(`@/assets/HomePageImages/photography-block/5.png`)">
                    </div>
                </div>
                <div class="order-4 order-md-0">
                    <div class="item item__6">
                        <!--                        <button>-->
                        <!--                            <img-->
                        <!--                                src="@/assets/HomePageImages/download-btn.svg">-->
                        <!--                        </button>-->
                        <img :src="require(`@/assets/HomePageImages/photography-block/6.png`)">
                    </div>
                    <div class="item item__7">
                        <!--                        <button>-->
                        <!--                            <img-->
                        <!--                                src="@/assets/HomePageImages/download-btn.svg">-->
                        <!--                        </button>-->
                        <img :src="require(`@/assets/HomePageImages/photography-block/7.png`)">
                    </div>
                </div>
                <div class="order-2 order-md-0 item item__8">
                    <!--                    <button>-->
                    <!--                        <img src="@/assets/HomePageImages/download-btn.svg">-->
                    <!--                    </button>-->
                    <img :src="require(`@/assets/HomePageImages/photography-block/8.png`)">
                </div>
                <div class="order-6 order-md-0">
                    <div class="item item__9">
                        <!--                        <button>-->
                        <!--                            <img-->
                        <!--                                src="@/assets/HomePageImages/download-btn.svg">-->
                        <!--                        </button>-->
                        <img :src="require(`@/assets/HomePageImages/photography-block/9.png`)">
                    </div>
                    <div class="item item__10">
                        <!--                        <button>-->
                        <!--                            <img-->
                        <!--                                src="@/assets/HomePageImages/download-btn.svg">-->
                        <!--                        </button>-->
                        <img :src="require(`@/assets/HomePageImages/photography-block/10.png`)">
                    </div>
                </div>
            </div>
        </div>
        <!--        <button v-if="deviceWidth()<=767">-->
        <!--            Try our Demo-->
        <!--        </button>-->
    </section>
</template>

<script>
    export default {
        methods: {
            // deviceWidth() {
            //     return window.innerWidth;
            // }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
