<template>
    <div>
        <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="welcome"
            id="welcome-block">
            <div class="welcome__slider slider">
                <div class="slider__content">
                    <img
                        class="slider__image"
                        src="@/assets/HomePageImages/logo.svg"
                        alt="unco small logo">
                    <h2 class="slider__title">
                        <span class="shadow--white">From</span> concept
                        <span class="shadow--white">to</span> photorealistic
                        visuals
                    </h2>
                    <hr class="slider__line">
                    <p class="slider__text">
                        With unco, you’ll be completely at ease from start to
                        finish. You’re in control every step of the way.
                    </p>
                </div>
                <ul class="slider__navigation">
                    <li
                        v-for="(slide, index) of slideComponents"
                        :key="index"
                        @click="selectSlide(index)"
                        class="slider__dot"
                        :class="{
                            'slider__dot--active': selectedSlide === index
                        }">
                        <img
                            src="@/assets/HomePageImages/active-dot.svg"
                            alt="dot-active">
                    </li>
                </ul>
            </div>
        </div>
        <div class="welcome-container">
            <video loop muted playsinline preload="auto">
                <source :src="require(`@/assets/video/from-concept-to-visuals.mp4`)" type="video/mp4">
                <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/from-concept-to-visuals.mp4`)">link to the video</a> instead.</p>
            </video>

            <div v-if="$vuetify.breakpoint.xsOnly" class="mobile-info-block">
                <img
                    src="@/assets/HomePageImages/logo.svg"
                    alt="unco">
                <div class="separation-line"/>
                <h6>From concept to photorealistic visuals</h6>
                <p>
                    With unco, you’ll be completely at ease from start to
                    finish. You’re in control every step of the way.
                </p>
            </div>

            <!--            <button class="button button&#45;&#45;green">-->
            <!--                Try it for Free-->
            <!--            </button>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "welcome-block",
        data() {
            return {
                selectedSlide: 0,
                slideComponents: [
                    "welcome-block",
                    "configuration-block",
                    "render-customing-block",
                    "specifications-block",
                    "beyond-photography-block",
                    "showcase-product-block",
                    "industries-block",
                    "customer-reviews-block"
                ],
                observer: null
            };
        },
        mounted() {
            this.$nextTick(() => {
                this.initIntersectionObserver();
            });
        },
        beforeDestroy() {
            this.observer.disconnect();
        },
        methods: {
            selectSlide(slide) {
                this.selectedSlide = slide;

                this.slideComponent.scrollIntoView({
                    block: "center",
                    behavior: "smooth"
                });
            },
            initIntersectionObserver() {
                const options = {
                    rootMargin: "0px",
                    threshold: 0.35
                };

                this.observer = new IntersectionObserver(
                    this.registerAreaIntersect,
                    options
                );

                this.slideComponents.forEach(id => {
                    const targetSection = document.getElementById(id);
                    this.observer.observe(targetSection);
                });
            },
            registerAreaIntersect(entries) {
                if (entries && entries[0].isIntersecting) {
                    this.selectedSlide = this.slideComponents.indexOf(
                        entries[0].target.id
                    );
                }
            }
        },
        computed: {
            slideComponent() {
                return document.getElementById(
                    this.slideComponents[this.selectedSlide]
                );
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
