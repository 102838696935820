<template>
    <div class="ring-configurator">
        <video loop muted playsinline>
            <source :src="require(`@/assets/video/you-create-we-handle-rest.mp4`)" type="video/mp4">
            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/you-create-we-handle-rest.mp4`)">link to the video</a> instead.</p>
        </video>
    </div>
</template>

<script>
    export default {
        name: "ring-configurator",
        props: {
            mobile: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
