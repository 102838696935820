<template>
    <section
        id="showcase-product-block"
        class="home-row">
        <div class="info-block">
            <h6>Showcase your product on any platform</h6>
            <div class="separation-line"/>
            <p>
                Take your digital product experiences to the next level—without
                the hassle.
            </p>
            <!--            <button v-if="deviceWidth() > 767">-->
            <!--                Learn More-->
            <!--            </button>-->
        </div>
        <div class="preview home-row">
            <div class="preview-controls">
                <a
                    v-for="tab in tabs"
                    :key="tab.value"
                    role="button"
                    @click="changeTab(tab.value)"
                    :class="['text-uppercase', {'is-active': tab.value === selectedTab}]">
                    {{ tab.label }}
                </a>
            </div>
            <div class="image-wrapper">
                <video v-show="selectedTab === 1" muted playsinline ref="video_1">
                    <source :src="require(`@/assets/video/e-commerce.mp4`)" type="video/mp4">
                    <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/e-commerce.mp4`)">link to the video</a> instead.</p>
                </video>
                <video v-show="selectedTab === 2" muted playsinline ref="video_2">
                    <source :src="require(`@/assets/video/marketing.mp4`)" type="video/mp4">
                    <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/marketing.mp4`)">link to the video</a> instead.</p>
                </video>
                <video v-show="selectedTab === 3" muted playsinline ref="video_3">
                    <source :src="require(`@/assets/video/social-media.mp4`)" type="video/mp4">
                    <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/social-media.mp4`)">link to the video</a> instead.</p>
                </video>
            </div>
            <!--            <button v-if="deviceWidth() <= 767">-->
            <!--                Learn More-->
            <!--            </button>-->
        </div>
    </section>
</template>

<script>
    export default {
        name: "showcase-product-block",
        data() {
            return {
                tabs: [
                    { value: 1, label: "E-commerce" },
                    { value: 2, label: "Social media" },
                    { value: 3, label: "Marketing" }
                ],
                selectedTab: 1
            }
        },
        methods: {
            deviceWidth() {
                return window.innerWidth;
            },
            changeTab(tabVal) {
                if (this.selectedTab === tabVal) return;
                this.selectedTab = tabVal;

                this.$nextTick(() => {
                    this.$refs[`video_${tabVal}`].play();
                })
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
