<template>
    <div class="center-setting configurator-options">
        <h4>Center Setting</h4>
        <v-list class="configurator-options-list pa-0">
            <v-list-item-group
                v-model="centerSetting"
                mandatory
                class="d-flex"
                @change="$emit('onChange', centerSetting)">
                <template v-for="(setting, idx) in centerSettings">
                    <v-list-item
                        :key="`item-${idx}`"
                        :value="setting.value"
                        :disabled="isDisabled(setting)"
                        :ripple="false"
                        class="configurator-option pa-0"
                        dark>
                        <template v-slot:default>
                            <v-list-item-content class="pa-0">
                                <div class="configurator-option__img-wrap d-flex flex-column align-center">
                                    <img
                                        v-for="img in setting.images"
                                        :key="img"
                                        :src="img"
                                        :alt="setting.label">
                                </div>
                                <p class="mb-0">{{ setting.label }}</p>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    export default {
        name: "center-setting",
        props: {
            config: {
                type: Object,
                default: null
            },
            unAvailableCombinations: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                centerSetting: "S",
                centerSettings: [
                    {
                        value: "S1",
                        label: "Classic",
                        images: [
                            "https://t6q7c3m8.stackpathcdn.com/images/builder/heads-7.png",
                            "https://t6q7c3m8.stackpathcdn.com/images/builder/heads-7-2.png"
                        ]
                    },
                    {
                        value: "H1",
                        label: "Halo",
                        images: [
                            "https://t6q7c3m8.stackpathcdn.com/images/builder/heads-1.png",
                            "https://t6q7c3m8.stackpathcdn.com/images/builder/heads-1-2.png"
                        ]
                    },
                    {
                        value: "H3",
                        label: "Cushion Halo",
                        images: [
                            "https://t6q7c3m8.stackpathcdn.com/images/builder/heads-3.png",
                            "https://t6q7c3m8.stackpathcdn.com/images/builder/heads-3-2.png"
                        ]
                    },
                    {
                        value: "H4",
                        label: "Vintage Cushion Halo",
                        images: [
                            "https://t6q7c3m8.stackpathcdn.com/images/builder/heads-4.png",
                            "https://t6q7c3m8.stackpathcdn.com/images/builder/heads-4-2.png"
                        ]
                    },
                    {
                        value: "H2",
                        label: "Vintage Halo",
                        images: [
                            "https://t6q7c3m8.stackpathcdn.com/images/builder/heads-2.png",
                            "https://t6q7c3m8.stackpathcdn.com/images/builder/heads-2-2.png"
                        ]
                    }
                ]
            }
        },
        methods: {
            isDisabled(setting) {
                const combinations = this.unAvailableCombinations.filter(comb => comb.centerSettings.includes(setting.value));
                return combinations.some(comb => {
                    return (comb.stoneShapes.length ? comb.stoneShapes.includes(this.config.stoneShape) : true) &&
                        (comb.mountingStyles.length ? comb.mountingStyles.includes(this.config.mountingStyle) : true) &&
                        (comb.gemstones.length ? comb.gemstones.includes(this.config.gemstone) : true)
                })
            }
        }
    }
</script>
