<template>
    <section id="industries-block">
        <div class="info-block text-center">
            <h6>Industries we <br> work with</h6>
            <div class="separation-line"/>
            <p class="mt-6 mx-auto">
                We are not only limited to jewelry industry. Unco also specialize in a wide variety of fields where you would need photorealistic renders.
            </p>
        </div>
        <div class="industries d-flex flex-wrap justify-center">
            <div
                class="industries-item d-flex flex-column align-center"
                v-for="(industry, idx) in industries"
                :key="idx">
                <img :src="require(`@/assets/HomePageImages/industries-block/${industry.image}.png`)" :alt="industry.label">
                <p class="mb-0 font-weight-bold text-center">{{ industry.label }}</p>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "industries-block",
        data() {
            return {
                industries: [
                    { label: "Hand Bags", image: "hand_bags" },
                    { label: "Watches", image: "watches" },
                    { label: "Architecture", image: "architecture" },
                    { label: "Transportation", image: "transportation" },
                    { label: "Clothing", image: "clothing" },
                    { label: "Sunglasses", image: "sunglasses" },
                    { label: "Consumer Electronics", image: "consumer_electronics" },
                    { label: "Furniture", image: "furniture" }
                ]
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
