<template>
    <div class="metal-color configurator-options">
        <h4>Metal Color</h4>
        <v-list class="configurator-options-list pa-0">
            <v-list-item-group
                v-model="metalColor"
                mandatory
                class="d-flex"
                @change="$emit('onChange', metalColor)">
                <template v-for="(color, idx) in colors[builder]">
                    <v-list-item
                        :key="`item-${idx}`"
                        :value="color.value"
                        class="configurator-option pa-0"
                        :ripple="false"
                        dark>
                        <template v-slot:default>
                            <v-list-item-content class="pa-0">
                                <div class="configurator-option__img-wrap">
                                    <img :src="color.image" :alt="color.label">
                                    <img class="configurator-option__checked-icon" src="@/assets/icons/ic-checked.svg" alt="">
                                </div>
                                <p class="mb-0">{{ color.label }}</p>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    export default {
        name: "metal-color",
        props: {
            builder: {
                type: String,
                default: "veraWang" // veraWang, taylorYou, silentOpus
            }
        },
        data() {
            return {
                metalColor: "W",
                colors: {
                    veraWang: [
                        { value: "W", label: "White", image: "https://t6q7c3m8.stackpathcdn.com/images/builder/white-gold-sample.png" },
                        { value: "Y", label: "Yellow", image: "https://t6q7c3m8.stackpathcdn.com/images/builder/yellow-gold-sample.png" },
                        { value: "R", label: "Rose", image: "https://t6q7c3m8.stackpathcdn.com/images/builder/rose-gold-sample.png" }
                    ],
                    taylorYou: [
                        { value: "WG", label: "White", image: "https://t6q7c3m8.stackpathcdn.com/images/builder/white-gold-sample.png" },
                        { value: "YG", label: "Yellow", image: "https://t6q7c3m8.stackpathcdn.com/images/builder/yellow-gold-sample.png" },
                        { value: "RG", label: "Rose", image: "https://t6q7c3m8.stackpathcdn.com/images/builder/rose-gold-sample.png" }
                    ]
                }
            }
        }
    }
</script>
