<template>
    <div class="reviews-slider home-row">
        <div
            v-if="deviceWidth() > 767"
            slot="button-prev"
            class="prev"
            :class="{ disabled: !allowSlidePrev }">
            <img
                src="@/assets/HomePageImages/prev-icon.svg"
                alt="prev">
        </div>
        <swiper
            ref="mySwiper"
            :options="swiperOptionsObject"
            :auto-update="true"
            :auto-destroy="true"
            :delete-instance-on-destroy="true"
            :cleanup-styles-on-destroy="true"
            :slides-per-view="1"
            @slideChange="activeIndexChange">
            <swiper-slide
                v-for="(review, idx) in reviews"
                :key="idx">
                <div class="review-block">
                    <p class="review-text">{{ review.text }}</p>
                    <div class="author">{{ review.client }}</div>
                </div>
            </swiper-slide>
        </swiper>
        <div
            v-if="deviceWidth() > 767"
            slot="button-next"
            class="next"
            :class="{ disabled: !allowSlideNext }">
            <img
                src="@/assets/HomePageImages/next-icon.svg"
                alt="next">
        </div>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from "vue-awesome-swiper";

    export default {
        components: {
            swiper: Swiper,
            "swiper-slide": SwiperSlide
        },
        data: () => ({
            swiperOptionsObject: {
                navigation: {
                    nextEl: ".next",
                    prevEl: ".prev"
                }
            },
            activeSlide: 0,
            allowSlidePrev: false,
            allowSlideNext: true,
            reviews: [
                { client: "SDC Designs", text: "Specially tailored platform for jewelry that I love! A straight forward process with minimal hassle, fast turnaround times. Couldn’t ask more from them." },
                { client: "Roxie", text: "I turned my earrings design into a photorealistic rendering in a couple of days. Working with them was a complete breeze." },
                { client: "Silent Opus", text: "I wasn’t expecting an easy process working on a project with 60,000+ renders but Unco team delivered everything in time!" },
                { client: "Taylor + You", text: "They turned my sketch and a short description of a design i had in mind in a couple of days! Love the photorealistic result they achieved." }
            ]
        }),
        methods: {
            activeIndexChange({ activeIndex, isBeginning, isEnd }) {
                this.activeSlide = activeIndex;
                this.allowSlideNext = !isEnd;
                this.allowSlidePrev = !isBeginning;
            },
            deviceWidth() {
                return window.innerWidth;
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
