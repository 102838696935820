<template>
    <div class="home-page">
        <application-welcome-block/>
        <application-main-block/>
    </div>
</template>

<script>
    import ApplicationMainBlock from "@/components/HomePageComponents/ApplicationMainBlock";
    import ApplicationWelcomeBlock from "@/components/HomePageComponents/ApplicationWelcomeBlock";

    export default {
        name: "page-home",
        components: {
            "application-welcome-block": ApplicationWelcomeBlock,
            "application-main-block": ApplicationMainBlock
        },
        data: () => ({
            observer: null
        }),
        mounted() {
            this.observer = new IntersectionObserver(this.onElementObserved, {
                threshold: 1
            });

            document.querySelectorAll("video").forEach(lazyVideo => {
                this.observer.observe(lazyVideo);
            });
        },
        beforeDestroy() {
            this.observer.disconnect();
        },
        methods: {
            onElementObserved(entries) {
                entries.forEach(({ target, isIntersecting }) => {
                    if (!isIntersecting) {
                        return;
                    }

                    target.play();
                    this.observer.unobserve(target);
                });
            }
        }
    };
</script>

<style>
    .home-page {
        font-family: 'Futura PT', sans-serif !important;
    }
    body {
        scroll-behavior: smooth;
    }
</style>
