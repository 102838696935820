<template>
    <div class="main-block-wrapper">
        <configuration-block/>
        <render-customing-block/>
        <specifications-block/>
        <beyond-photography-block/>
        <showcase-product-block/>
        <industries-block/>
        <customer-reviews-block/>
    </div>
</template>

<script>
    import ConfigurationBlock from "@/components/HomePageComponents/ConfigurationBlock";
    import RenderCustomingBlock from "@/components/HomePageComponents/RenderCustomingBlock";
    import SpecificationsBlock from "@/components/HomePageComponents/SpecificationsBlock";
    import BeyondPhotographyBlock from "@/components/HomePageComponents/BeyondPhotographyBlock"
    import ShowcaseProductBlock from "@/components/HomePageComponents/ShowcaseProductBlock";
    import IndustriesBlock from "@/components/HomePageComponents/IndustriesBlock";
    import CustomerReviewsBlock from "@/components/HomePageComponents/CustomerReviewsBlock";

    export default {
        name: "application-main-block",
        components: {
            "configuration-block": ConfigurationBlock,
            "render-customing-block": RenderCustomingBlock,
            "specifications-block": SpecificationsBlock,
            "beyond-photography-block": BeyondPhotographyBlock,
            "showcase-product-block": ShowcaseProductBlock,
            "industries-block": IndustriesBlock,
            "customer-reviews-block": CustomerReviewsBlock
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
