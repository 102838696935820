<template>
    <section
        id="configuration-block"
        class="home-row">
        <div class="info-block">
            <h6>
                You create, <br v-if="deviceWidth() <= 767">we handle the rest
            </h6>
            <div class="separation-line"/>
            <p>
                We put your ideas to work by handling the rendering production
                process for you.
            </p>
            <ring-configurator
                v-if="deviceWidth() <= 767"
                mobile/>
            <button @click="$router.push({name: 'registration'})">Create Now</button>
        </div>
        <div
            class="ring-configurator-wrapper"
            v-if="deviceWidth() > 767">
            <ring-configurator class="configurator"/>
        </div>
    </section>
</template>

<script>
    import RingConfigurator from "@/components/HomePageComponents/RingConfigurator/index.vue";

    export default {
        name: "configuration-block",
        components: {
            "ring-configurator": RingConfigurator
        },
        methods: {
            deviceWidth() {
                return window.innerWidth;
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
