<template>
    <div class="mounting-style configurator-options">
        <h4>Mounting Style</h4>
        <v-list class="configurator-options-list pa-0">
            <v-list-item-group
                v-model="mountingStyle"
                mandatory
                class="d-flex"
                @change="$emit('onChange', mountingStyle)">
                <template v-for="(style, idx) in mountingStyles">
                    <v-list-item
                        :key="`item-${idx}`"
                        :value="style.value"
                        :disabled="isDisabled(style)"
                        :ripple="false"
                        class="configurator-option pa-0"
                        dark>
                        <template v-slot:default>
                            <v-list-item-content class="pa-0">
                                <div class="configurator-option__img-wrap">
                                    <img :src="style.image" :alt="style.label">
                                </div>
                                <p class="mb-0">{{ style.label }}</p>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    export default {
        name: "mounting-style",
        props: {
            config: {
                type: Object,
                default: null
            },
            unAvailableCombinations: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                mountingStyle: "1",
                mountingStyles: [
                    {
                        value: "1",
                        label: "Harmony Spirit",
                        image: "https://t6q7c3m8.stackpathcdn.com/images/builder/XS16.png"
                    },
                    {
                        value: "11",
                        label: "Heirloom Vintage Braid",
                        image: "https://t6q7c3m8.stackpathcdn.com/images/builder/XS2.png"
                    },
                    {
                        value: "10",
                        label: "Heirloom Vintage Straight",
                        image: "https://t6q7c3m8.stackpathcdn.com/images/builder/XS6.png"
                    },
                    {
                        value: "9",
                        label: "Heirloom Vintage Edge",
                        image: "https://t6q7c3m8.stackpathcdn.com/images/builder/XS14.png"
                    },
                    {
                        value: "4",
                        label: "Simplicity Duo Side",
                        image: "https://t6q7c3m8.stackpathcdn.com/images/builder/XS4.png"
                    }
                ]
            }
        },
        methods: {
            isDisabled(style) {
                const combinations = this.unAvailableCombinations.filter(comb => comb.mountingStyles.includes(style.value));
                return combinations.some(comb => {
                    return (comb.stoneShapes.length ? comb.stoneShapes.includes(this.config.stoneShape) : true) &&
                        (comb.centerSettings.length ? comb.centerSettings.includes(this.config.centerSetting) : true) &&
                        (comb.gemstones.length ? comb.gemstones.includes(this.config.gemstone) : true)
                })
            }
        }
    }
</script>
