<template>
    <section
        id="specifications-block"
        class="home-row">
        <div class="preview">
            <video v-if="$vuetify.breakpoint.smAndUp" loop muted playsinline>
                <source :src="require(`@/assets/video/adjust-specifications.mp4`)" type="video/mp4">
                <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/adjust-specifications.mp4`)">link to the video</a> instead.</p>
            </video>
            <!-- Hack for mobile Safari. For some reason mobile Safari doesn't support this video for older versions of iOS. -->
            <img v-if="$vuetify.breakpoint.xsOnly" src="@/assets/video/adjust-specifications.gif" alt="">
            <!--            <button v-if="deviceWidth() <= 767">-->
            <!--                See How-->
            <!--            </button>-->
        </div>
        <div class="info-block">
            <h6>Adjust and add specifications to your design</h6>
            <div class="separation-line"/>
            <p>
                Choose each facet of your 3D rendered model. Perfect your
                creation by guiding its design.
            </p>
            <!--            <button v-if="deviceWidth() > 767">-->
            <!--                See How-->
            <!--            </button>-->
        </div>
    </section>
</template>

<script>
    export default {
        name: "specifications-block",
        methods: {
            deviceWidth() {
                return window.innerWidth;
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
